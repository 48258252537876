<div class="app-container overflow-auto">
  <main class="main-content">
 
    <div class="batery-card-list">
      <div class="card-row" [ngStyle]="{
        'grid-template-columns': 'repeat('+minWidth+', 1fr)'
      }">
        <!-- Inner loop for the items in each row -->
        <div class="card-item" *ngFor="let item of cards">
          <span class="icon is-charging"><mat-icon class="material-symbols-outlined" [ngStyle]="{'font-size': iconSize +'px', 'width':iconSize +'px', 'height':iconSize +'px'}">visibility</mat-icon></span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span [ngStyle]="{'font-size': mainSize +'px'}">0176</span>
            <small [ngStyle]="{'font-size':smallSize +'px'}">h3 m10</small>
          </span>
        </div>
      </div>
    </div>
  </main>
</div>