import { Component } from '@angular/core';

@Component({
  selector: 'app-presences',
  templateUrl: './presences.component.html',
  styleUrl: './presences.component.scss'
})
export class PresencesComponent {

}
