import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentData, DocumentReference, DocumentSnapshot} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import firebase from 'firebase/compat/app';
import {Timestamp} from 'firebase/firestore'
import * as moment from "moment";
import {doc, getDoc, Firestore} from "@angular/fire/firestore";
import {id} from "@swimlane/ngx-charts";

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(
    private firestore: Firestore,
    private afs: AngularFirestore,
    private angularFireFunctions: AngularFireFunctions,
    private authService: AuthService,
    public http: HttpClient) {
  }

  getAllClients(): Observable<any> {
    return this.afs.collection('clients').valueChanges({idField: 'id'});
  }

  getClientById(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).valueChanges({idField: 'id'});
  }

  getLocationByIdForClientId(locationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).ref.get();
  }

  getLayoutByIdForLocIdClientId(layoutId: string, locationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).ref.get();
  }

  getAllLocationsForClientId(clientId): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').valueChanges({idField: 'id'});
  }

  getAllLayoutsForLocIdForClientId(locationId: string, clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').valueChanges({idField: 'id'});
  }

  getAllRowsForLytLocClientId(layoutId: string, locationId: string, clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows', (ref) =>
      ref.where('isArchived', '==', false)
    ).get();
  }

  getAllRowsForClientId(clientId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('isArchived', '==', false).orderBy('rowNumber', 'asc')
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedRowsForClientId(clientId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  getAllRowsForLocIdForClientId(clientId: string, locationId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('locationId', '==', locationId).where('isArchived', '==', false).orderBy('rowNumber', 'asc')
    ).valueChanges({idField: 'id'});
  }

  getLanguageJSON(languageCode): Observable<any> {
    return this.afs.collection('languages').doc(languageCode).get();
  }

}
