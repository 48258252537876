import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-device-last-seen',
  templateUrl: './device-last-seen.component.html',
  styleUrl: './device-last-seen.component.scss'
})
export class DeviceLastSeenComponent implements OnInit {
  cards:any
  totalRows:any;
  constructor(private renderer: Renderer2, private elRef: ElementRef) {}
  ngOnInit(): void {
    this.getRows()
    this.cards = Array.from({ length: 32 });

    this.updateCardSize();
  }
  getRows() {
    const rows = [];
    const itemsArray = new Array(50).fill(0);

    for (let i = 0; i < 50; i += 6) {
      rows.push(itemsArray.slice(i, i + 6)); 
    }
    
    this.totalRows = rows;
    console.log('row',this.totalRows)
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardSize(); // Update grid styles on resize
  }

  minWidth : number
  cardSize: number
  iconSize:number
  mainSize:number
  smallSize:number
  updateCardSize(): void {
    const itemCount = this.cards.length;
    let countGap = itemCount*5
    const screenWidth = window.innerWidth - countGap;
    const screenHeight = window.innerHeight;
    const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
    const rows = Math.ceil(itemCount / columns);
    let ss  = itemCount * (screenWidth / screenHeight);
    if(rows > columns){
      this.minWidth = columns;
    }
    else{
      this.minWidth = Math.floor(ss / columns)
    }
   
    this.cardSize = ((window.innerWidth-60-countGap) / itemCount) * columns;
    this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));  
    this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065)); 
    this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));
   
    
  }

  calculateCardSize(itemCount: number): number {
    const baseSize = 1670; // Base card size
    const minSize = 50; // Minimum card size for many items

    // Calculate size inversely proportional to the number of items
    return Math.max(baseSize - itemCount * 2, minSize);
  }
}
