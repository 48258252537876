<div class="app-container overflow-auto">
  <main class="main-content">
 
    <div class="batery-card-list">
      <h2 class="group-name">group 1</h2>
      <div class="card-row" >
        <!-- Inner loop for the items in each row -->
        <div class="card-item" *ngFor="let item of [].constructor(10);let i = index">
          <span class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span>Zaar S</span>
            <small style="font-size: 11px;">Task Name</small>
          </span>
        </div>
      </div>
      <h2 class="group-name">group 2</h2>
      <div class="card-row" >
        <!-- Inner loop for the items in each row -->
        <div class="card-item" *ngFor="let item of [].constructor(20);let i = index">
          <span class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span>Dhurv R</span>
            <small style="font-size: 11px;">Task B</small>
          </span>
        </div>
      </div>
      <h2 class="group-name">group 3</h2>
      <div class="card-row" >
        <!-- Inner loop for the items in each row -->
        <div class="card-item" *ngFor="let item of [].constructor(13);let i = index">
          <span class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span>Sebestian Z</span>
            <small style="font-size: 11px;">Task C Name</small>
          </span>
        </div>
      </div>
    </div>
  </main>
</div>