import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-device-battery',
  templateUrl: './device-battery.component.html',
  styleUrl: './device-battery.component.scss'
})
export class DeviceBatteryComponent implements OnInit {
  cards:any
  totalRows:any;
  constructor(private renderer: Renderer2, private elRef: ElementRef) {}
ngOnInit(): void {
  this.cards = Array.from({ length: 32 });
  this.updateCardSize(); 
}

@HostListener('window:resize')
onResize(): void {
  this.updateCardSize(); // Update grid styles on resize
}

minWidth : number
cardSize: number
iconSize:number
mainSize:number
smallSize:number
updateCardSize(): void {
  const itemCount = this.cards.length;
  let countGap = itemCount*5
  const screenWidth = window.innerWidth - countGap;
  const screenHeight = window.innerHeight;
  const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
  const rows = Math.ceil(itemCount / columns);
  let ss  = itemCount * (screenWidth / screenHeight);
  if(rows > columns){
    this.minWidth = columns;
  }
  else{
    this.minWidth = Math.floor(ss / columns)
  }
 
  this.cardSize = ((window.innerWidth-60-countGap) / itemCount) * columns;
  this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));  
  this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065)); 
  this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));
 
  
}
}